import React from "react";
import "../styles/videos.css";
import { graphql, useStaticQuery } from "gatsby";
import moment from "moment";
import Layout from "../components/layout";

const Video = () => {
  const data = useStaticQuery(graphql`
    query YoutubeFull {
      allYoutubeVideo(sort: { fields: [publishedAt], order: DESC }) {
        edges {
          node {
            videoId
            title
            description
            publishedAt
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="Joeli Toscano | Videos"
      description="Joeli Toscano - Journalist. A collection of Joeli Toscano's videos on Youtube. Vlog posts on Youtube by Joeli Toscano. All videos from Joeli Toscano's Youtube channel."
    >
      <div className="videos-container">
        <div className="video-column">
          {data.allYoutubeVideo.edges.map((video) => (
            <div className="video-rows" key={video.node.videoId}>
              <iframe
                className="youtube-embed"
                src={`https://www.youtube.com/embed/${video.node.videoId}`}
                title={video.node.title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="video-text">
                <h2>{video.node.title}</h2>
                <h3>{moment(video.node.publishedAt).format("LL")}</h3>
                <p>{video.node.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Video;
